import React, { FC } from "react"
import { graphql, HeadFC, PageProps } from "gatsby";
import { BaseLayout } from "@layouts";
import { GatsbyImage } from "gatsby-plugin-image";
import { Quote } from "@components";
import "./index.scss"

type PhotosReviewsData = Queries.PhotosReviewsQuery
type PhotosReviewsContext = {
    locale: string
}

const PhotosReviews: FC<PageProps<PhotosReviewsData, PhotosReviewsContext>> = ( { data } ) => {

    return (
        <BaseLayout>
            <h1>Photos & Reviews</h1>

            <GatsbyImage
                alt={ "" }
                image={ data.image12?.childImageSharp?.gatsbyImageData! }
                layout="fullWidth"
            />

            <div className={ 'two-columns section' }>
                <Quote data={ reviewsQuotes["1"] }
                       preContent={
                           <GatsbyImage
                               alt={ "" }
                               image={ data.image2?.childImageSharp?.gatsbyImageData! }
                           />
                       }
                />
                <Quote data={ reviewsQuotes["2"] }/>
            </div>

            <GatsbyImage
                alt={ "" }
                image={ data.image3?.childImageSharp?.gatsbyImageData! }
                layout="fullWidth"
            />

            <div className={ 'section' }>
                <Quote data={ reviewsQuotes["3"] }/>
            </div>
            <hr className={ 'divider bg--striped' }/>
            <div className={ 'two-columns crow bg--striped' }>
                <GatsbyImage
                    alt={ "" }
                    image={ data.image4?.childImageSharp?.gatsbyImageData! }
                />
                <GatsbyImage
                    alt={ "" } objectFit={ 'contain' }
                    image={ data.image5?.childImageSharp?.gatsbyImageData! }
                />
            </div>
            <hr className={ 'divider bg--striped' }/>
            <div className={ "two-columns items-center" }>
                <div className={ 'section flow' } data-flow-space='lg'>
                    <Quote data={ reviewsQuotes["4"] }/>
                    <Quote data={ reviewsQuotes["5"] }/>
                </div>
                <GatsbyImage
                    className={ 'h-100' }
                    alt={ "" }
                    image={ data.image6?.childImageSharp?.gatsbyImageData! }
                />
            </div>
            <hr className={ 'divider bg--striped' }/>
            <div className={ "two-columns photos bg--striped" }>
                <GatsbyImage
                    alt={ "" }
                    image={ data.image7?.childImageSharp?.gatsbyImageData! }
                />
                <GatsbyImage
                    alt={ "" }
                    image={ data.image8?.childImageSharp?.gatsbyImageData! }
                />
            </div>
            <hr className={ 'divider bg--striped' }/>
            <div className={ 'section' }>
                <Quote data={ reviewsQuotes["6"] }/>
            </div>
            <hr className={ 'divider bg--striped' }/>
            <GatsbyImage
                alt={ "" }
                image={ data.image1?.childImageSharp?.gatsbyImageData! }
                layout="fullWidth"
            />
            <hr className={ 'divider bg--striped' }/>
            <div className={ 'section' }>
                <Quote data={ reviewsQuotes["7"] }/>
            </div>

            <GatsbyImage
                alt={ "" }
                image={ data.image9?.childImageSharp?.gatsbyImageData! }
            />

            <div className={ 'two-columns head' }>
                <GatsbyImage
                    alt={ "" }
                    objectFit={ 'contain' }
                    image={ data.image11?.childImageSharp?.gatsbyImageData! }
                />
                <div className={ 'section flow' } data-flow-space={ 'md' }>
                    <Quote data={ reviewsQuotes["8"] }/>
                    <Quote data={ reviewsQuotes["9"] }/>
                    <Quote data={ reviewsQuotes["10"] }/>
                </div>
            </div>

            <GatsbyImage
                alt={ "" }
                image={ data.image10?.childImageSharp?.gatsbyImageData! }
            />
        </BaseLayout>
    )
}

export default PhotosReviews

export const Head: HeadFC = () => <>
    <title>Photos & Reviews</title>
</>

export const query = graphql`
    query PhotosReviews {
        image1: file(relativePath: {eq: "reviews/01.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image2: file(relativePath: {eq: "reviews/02.png"}) {
            relativePath
            childImageSharp {
                gatsbyImageData
            }
        }
        image3: file(relativePath: {eq: "reviews/03.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image4: file(relativePath: {eq: "reviews/04.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image5: file(relativePath: {eq: "reviews/05.png"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image6: file(relativePath: {eq: "reviews/06.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image7: file(relativePath: {eq: "reviews/07.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image8: file(relativePath: {eq: "reviews/08.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image9: file(relativePath: {eq: "reviews/09.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image10: file(relativePath: {eq: "reviews/10.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image11: file(relativePath: {eq: "reviews/11.png"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        image12: file(relativePath: {eq: "reviews/12.jpg"}) {
            relativePath
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`

export const reviewsQuotes = {
    1 : {
        text : "This is the album that establishes Maplerun as part of the elite group of their sound, both nationally and internationally",
        cite : "METAL HAMMER (GREECE) 8/10",
        extra: "May 2017 / Issue 389"
    },
    2 : {
        text : "Partykrasher is an album that gets your attention from the beginning until the end with its compositions and energy. Maplerun take huge steps ahead and show us that if they continue working like that, a bright future awaits them. Soon they will start touring, so if you have the chance don’t miss them. They are one of the best Greek live acts that anyone can see",
        cite : "ROCKPAGES.GR",
        extra: "Webzine"
    },
    3 : {
        text : "…these guys work hard and above all, they give 101% of their skills. The crowd embraced them and had the luck to see one of the best Greek support bands of all time… excellent in every sense of the word. With performances like this one, if they are a little lucky, they will achieve the big bet...",
        cite : "METALPATHS",
        extra: "Webzine"
    },
    4 : {
        text : "…they sound like Stone Sour and Coldplay and at times their atmosphere might remind you of Katatonia. I can surely say that MAPLERUN are very good at what they do and that they will attract many dedicated fans.",
        cite : "METAL HAMMER (GREECE) 7/10",
        extra: "March ’09/ Issue 291"
    },
    5 : {
        text : "Maplerun will make you wonder why such a band is still in Greece and hasn’t yet moved to the other side of the Atlantic were they surely belong. What exactly is it that other bands have and Maplerun lack? Great songs? Open up your ears and just listen to “Restless”. A lot of bands would love to have one of their songs in their tracklist.",
        cite : "GREEK REBELS 8.5/10",
        extra: "Webzine"
    },
    6 : {
        text : "…these guys work hard and above all, they give 101% of their skills. The crowd embraced them and had the luck to see one of the best Greek support bands of all time… excellent in every sense of the word. With performances like this one, if they are a little lucky, they will achieve the big bet...",
        cite : "KOURDISTIRI.GR",
        extra: "Webzine"
    },
    7 : {
        text : "…with obvious talent and in the mood for music, Maplerun’s performance convinced me to check them out further on. My personal opinion is that they stood out amongst the other opening bands…",
        cite : "ROCKING.GR",
        extra: "Webzine"
    },
    8 : {
        text : "After listening to the album for the first time I decided to set it on repeat. I like the tracklisting, the production, the albums ending, the influences from System of a Down and Nickelback and I really like the vocals…the music and production of the album is perfect",
        cite : "ROCK OVERDOSE RATING 88/100",
        extra: "Webzine (GREECE)"
    },
    9 : {
        text : "…The guys had a blast and poured sweat all over the stage…after performing tracks from both their albums they said goodnight and left us searching the floor for our jaws…",
        cite : "ROCKINATHENS.GR",
        extra: "Webzine"
    },
    10: {
        text : "Eventhough I had previously heard them on youtube nothing could prepare me for what I would see live in front on me…I kept staring at them with my mouth open…crazy headbanging and a general music craziness that I have never experienced before.",
        cite : "ROADCREW.GR",
        extra: "Webzine"
    }
}
